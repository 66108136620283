section.content-block.template-services {
    .service-image {
        display: block;
        margin-bottom: rem-calc(24);
        text-align: center;
    }

    .service-title {
        display: block;
        text-align: center;
        text-transform: uppercase;
        font-size: rem-calc(18);
        font-weight: 900;
        letter-spacing: 1.84px;
    }
}