section.content-block.template-services-list {
    margin-top: 40px !important;
    h2 {
        @include responsive-type(46, percent(46, 20));
        margin-bottom: 30px;
    }

    h3 {
        @include responsive-type(37, percent(37, 20));
    }

    .buttons.inline {
        margin-bottom: 55px;
        .button {
            margin-right: 20px;
            margin-bottom: 20px;
        }
    }

    .block-grid {
        margin-bottom: -100px;

        > .cell {
            margin-bottom: 100px;
        }
        .image {
            margin-bottom: 35px;
        }
        h3 {
            margin-bottom: 15px;
        }

        .description {
            font-size: 20px;
            line-height: 1.7;
            flex-grow: 1;
            margin-bottom: 25px;

            b {
                font-weight: 500;
            }
        }

        .inner {
            display: flex;
            flex-direction: column;
            height: 100%;
        }

        .buttons {
            .button {
                margin-bottom: 20px;
                min-width: 70%;

                &:last-child {
                    margin-bottom: 0;
                }

                &.secondary {
                    min-width: 95%;
                }
            }
        }
    }
}