header.template-header {
  margin-bottom: rem-calc(35);
  text-transform: uppercase;

    &.align-center {
        text-align: center;
    }

    .headline {
        span {
            display: block;
        }
    }
}