$offcanvas-background: color('primary');
$offcanvas-background-opacity: 1;
$offcanvas-links-color: color('inverse');
$offcanvas-text-color: color('inverse');

//off-canvas content
.off-canvas {
    background: rgba($offcanvas-background, $offcanvas-background-opacity);
    color: $offcanvas-text-color;
    overflow: auto;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    transform: translateX(100%);
    transition: transform $tr;
    padding: rem-calc(120 25 50);
    visibility: hidden;


    &.is-open {
        visibility: visible;
    }
    //color of the links 
    a:link,
    a:visited {
        color: $offcanvas-links-color;
    }

    &.is-open {
        transform: translateX(0);
    }

    //menu
    .menu {
        a {
            padding: 0;
        }

        &.drilldown {
            overflow: visible;
        }

        .menu-image {
            display: none;
        }
    }

    .accordion-menu {
        > li {
         
            > a {
                font-weight: 500;
                text-transform: uppercase;
                font-size: rem-calc(18);
                line-height: rem-calc(40);
            }

            &.current-menu-item {
                > a {
                    font-weight: 900;
                }
             
            }

            .submenu-toggle {
                width: 30px;
                height: 30px;

                &:after {
                    border: 0;
                    display: block;
                    background-image: svg-uri('<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="11.6px" height="7px" viewBox="0 0 11.6 7" style="enable-background:new 0 0 11.6 7;" xml:space="preserve"><polygon points="6.1,7 0,0.9 0.9,0 6.1,5.2 10.7,0.7 11.6,1.6" fill="#fff"/></svg>');
                    width: rem-calc(12);
                    height: rem-calc(7);
                    background-size: contain;
                }
            }

            ul {
                margin-left: rem-calc(20);
                li {
                    a {
                        line-height: rem-calc(30);
                    }
                
                }
            }
        }
    }

    //close button
    .close-button {
        @include closeButton($offcanvas-text-color);
        position: absolute;
        right: rem-calc(30);
        top: rem-calc(20);
    }

    .header-call {
        text-align: left;
    }
}

.off-canvas-wrapper {
    overflow: visible;
}

.off-canvas.is-transition-overlap {
    z-index: 9999;

    &.is-open {
        box-shadow: none;
    }
}