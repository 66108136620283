.module.buttons {
    .button {
        margin: 0;
    }

    .button-wrap {
        margin-bottom: rem-calc($module-margin * 0.5);

        &:last-child {
            margin-bottom: 0;
        }

        @include breakpoint(medium) {
            display: inline-block;
            margin-bottom: 0;
        }

        +.button-wrap {
            @include breakpoint(medium) {
                margin-left: rem-calc($module-margin);
            }
        }
    }

    &.align-center {
        text-align: center;
    }
}