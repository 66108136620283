.module {

    +.module {
        @include responsive-margin("top", $module-margin);
    }

    &.stack-order-1 {
        margin-top: 0;
    }

    &.align-center {
        text-align: center;
    }
}