section.content-block.template-advantages {
    .title {
        @include typo('caption');
        text-align: center;
        margin-bottom: rem-calc(20);
    }

    .icon {
        text-align: center;
        margin-bottom: rem-calc(15);
    }

    .description {
        line-height: 1.982;;
    }

    .template-header {
        margin-bottom: rem-calc(60);
    }

    @include breakpoint(large) {
        margin-top: rem-calc(120);

        + section.content-block {
            margin-top: rem-calc(120);
        }
    }
}
