.dropdown.menu>li>a,
.dropdown.menu>li.is-active>a,
.menu .active>a {
    background: inherit;
    color: inherit;
}

.menu,
.menu.dropdown {
    li.button,
    > li.button {
        padding: 0;
        background: none;
        height: auto;
        display: block;
        text-align: left;

        > a {
            @extend .button;
        }
    }
}

.menu.dropdown {
    > li {
        &.is-dropdown-submenu-parent {
            > a {
                padding-right: 0;

                &::after {
                    display: none;
                }
            }
        }

        //submenu
        ul {
            display: block;
            pointer-events: none;
            visibility: hidden;
            opacity: 0;
            transform: translate(0, 10px);
            transition: opacity 0.3s ease-in, transform 0.3s ease-in, visibility 0.3s ease-in;
        }

        //active state            
        &.is-active {
           ul {
               opacity: 1 !important;
               transform: translate(0, 0) !important;
               visibility: visible !important;
               pointer-events: auto !important;
           }
       }
    }
}

.primary-navigation {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    > ul.menu {
        width: 100%;
        justify-content: flex-end;
        position: static;

        a {
            padding: 0;
            color: color('dark-grey');

            &:hover {
                color: color('red');
            }
        }

        > li {
            margin-left: rem-calc(30);
            padding-bottom: rem-calc(25);
            position: static;

            &:first-child {
                margin-left: 0;
            }

            @include breakpoint(1050) {
                margin-left: rem-calc(70); 
            }

            > a {
                font-size: rem-calc(24);
                font-weight: 500;
                text-transform: uppercase;

                &:hover {
                    color: color('dark-grey');
                }
              

            }

            &.current-menu-item {
                > a {
                    font-weight: 900;
                }
            }

            &.is-dropdown-submenu-parent  {
                .circle {
                    content: '';
                    display: block;
                    z-index: 1;
                    width: rem-calc(75);
                    height: rem-calc(75);
                    background: $white;
                    box-shadow: 3px 4px 15px rgba(0,0,0,0.10);
                    border-radius: 50%;
                    position: absolute;
                    top: rem-calc(-15);
                    pointer-events: none;
                    left: 60%;
                }

                > a {
 
                    &:after {
                        content: '';
                        display: block;
                        position: absolute;
                        background-image: svg-uri('<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="11.6px" height="7px" viewBox="0 0 11.6 7" style="enable-background:new 0 0 11.6 7;" xml:space="preserve"><polygon points="6.1,7 0,0.9 0.9,0 6.1,5.2 10.7,0.7 11.6,1.6" fill="#{color('dark-grey')}"/></svg>');
                        width: rem-calc(12);
                        height: rem-calc(7);
                        background-size: contain;
                        background-repeat: no-repeat;
                        bottom: rem-calc(-20);
                        top: auto;
                        left: 50%;
                        margin-left: rem-calc(-6);
                        border: 0;
                        transition: background 0.3s;
                        right: auto;
                        margin: 0;
                    }
                }

                &:hover > a{
                    color: color('dark-grey');
                    &:after {
                        background-image: svg-uri('<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="11.6px" height="7px" viewBox="0 0 11.6 7" style="enable-background:new 0 0 11.6 7;" xml:space="preserve"><polygon points="6.1,7 0,0.9 0.9,0 6.1,5.2 10.7,0.7 11.6,1.6" fill="#{color('dark-grey')}"/></svg>');
                    }
                }

            }

            > ul.submenu {
                width: 100%;
                border: 0;
                box-shadow: 3px 4px 15px rgba(0,0,0,0.10);
                display: flex;
                flex-direction: row;
                justify-content: center;
                padding: rem-calc(50 25 0);
                pointer-events: none;
                visibility: hidden;
                opacity: 0;
                position: absolute;

                &:after {
                    content: '';
                    @include overlay();
                    pointer-events: none;
                    background: white;
                    z-index: 2;
                }

                >li {
                  max-width: rem-calc(490);
                  position: relative;
                  z-index: 3;
                  position: relative;
                  min-height: rem-calc(290);
                  padding-bottom: rem-calc(140);
                  padding-left: rem-calc(45);
                  padding-right: rem-calc(45);

                  &.current-menu-item > a {
                      font-weight: 900;
                  }


                  &.is-active,
                  &:hover,
                  &:focus,
                  &:active {
                      background: none;

                      > a {
                          background: none;
                      }
                  }

                  &:not(:last-child) {
                      &:after {
                          content: '';
                          display: block;
                          width: 1px;
                          height: 90%;
                          top: 0;
                          right: 0;
                          background: #BBB5A7;
                          position: absolute;
                      }
                  }

                  .menu-image {
                      position: absolute;
                      bottom: 0;
                      left: 50%;
                      transform: translateX((-50%));

                  }

                  > a {
                      font-family: fontFamily('secondary');
                      font-weight: 700;
                     font-size: rem-calc(23);
                     margin-bottom: rem-calc(30);
                     display: inline-block;
                     &:after {
                       content: '';
                       border: 0;
                       padding: 0;
                       margin: 0;
                       width: rem-calc(29);
                       height: rem-calc(29);
                       position: relative;
                       display: inline-flex;
                       align-items: center;
                       justify-content: center;
                       background: color('red') url(../images/arrow.svg) no-repeat 50% 50%;
                       border-radius: 50%;
                       right: 0;
                        vertical-align: middle;
                        margin-left: 20px;
                    }
                  }

                  ul {
                      display: block;
                      position: static;
                      display: block;
                      visibility: visible;
                      opacity: 1;
                      transform: none;
                      border: 0;
                      background: none;

                      > li {

                        &:not(:last-child) {
                            margin-bottom: rem-calc(15);
                        }
                        > a {
                            font-size: rem-calc(16);

                            &:after {
                                display: none !important;
                            }
                        }

                        &.current-menu-item > a {
                            font-weight: 900;
                        }
      
                      }
                  }
                }
            }
        }
    }
}