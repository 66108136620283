$input-height: rem-calc(50);
$input-paddings: rem-calc(10 20);
$input-border: 1px solid #A3BBC7;

input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type='file']),
select,
textarea {
    width: 100%;
    box-shadow: none;
    position: relative;
    margin: 0;
    padding: $input-paddings;
    height: $input-height;
    border: $input-border;
    font-size: rem-calc(12);
    letter-spacing: 2px;
    outline: none;
    font-weight: 500;
    color: #595855;

    &::placeholder {
        color: inherit;
    }

    &:focus {
        // remove placeholder on input :focus by default
        &::placeholder {
            color: transparent;
        }

        border-color: #BBB5A7;
    }
}

select {
    cursor: pointer;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
    background-image: svg-uri('<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 18.6 9.7" style="enable-background:new 0 0 18.6 9.7;" xml:space="preserve"><path d="M9.6,9.7c-0.3,0-0.7-0.1-1-0.3L0.5,2.7c-0.6-0.5-0.7-1.5-0.2-2.1c0.5-0.6,1.5-0.7,2.1-0.2l7.1,5.9l6.5-5.9 c0.6-0.6,1.6-0.5,2.1,0.1c0.6,0.6,0.5,1.6-0.1,2.1l-7.5,6.7C10.3,9.6,10,9.7,9.6,9.7z"/></svg>');
    background-origin: content-box;
    background-position: right 0 center;
    background-repeat: no-repeat;
    background-size: 9px 6px;
    padding-right: 1rem;
}

textarea {
    height: auto;
}


.form-field {
    padding: rem-calc(0 5);
    margin-bottom: rem-calc(20);
    width: 100%;

    &.half-width {
        width: 50%;
    }
}

.form-body {
    margin-left: rem-calc(-5);
    margin-right: rem-calc(-5);
    display: flex;
    flex-wrap: wrap;
}

.form-control {
    text-align: center;
    margin-top: rem-calc(60);

    .button {
        text-transform: uppercase;
        letter-spacing: 2px;
        font-size: rem-calc(16);
        font-weight: 500;
        width: 100%;
        max-width: rem-calc(360);


        @include breakpoint(large) {
            min-height: rem-calc(90);
        }
    }
}

.message {
    margin-bottom: 30px;

    &.message-error { color: red;}
    &.message-ok { color: green;}
}