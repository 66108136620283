section.content-block.template-contact {
    text-align: center;
    @include responsive-type(21, 16);
    font-weight: 500;
    color: color('red');
    word-break: break-all;

    &[class*=" bg-"] {
        padding: rem-calc(35 0);
    }

   a {
       font-weight: 700;
       color: color('red');
       @include responsive-type(29, 21);
   }

   &.bg-dark {
       color: $white;

       a {
           color: $white;
       }
   }
}
