footer.site-footer {
    background: color('grey');
    padding: rem-calc(60 0);
    font-size: rem-calc(16);
    line-height: 1.31;

    @include breakpoint(medium) {
        padding: rem-calc(120 0); 
    }

    .footer-logos {
        img {
            display: block;
            margin: 0 auto rem-calc(20);
        }
    }

    .footer-brand-logo {
        display: block;
        margin: 0 auto rem-calc(70);
        max-width: rem-calc(203);
        width: 100%;
    }

    .footer-title {
        @include responsive-type(24,18);
        color: color('inverse');
        font-weight: 500;
        margin-bottom: rem-calc(20);

        &.border {
            border-bottom: 1px solid $white;
            padding-bottom: rem-calc(25);
        }
    }

    div + .footer-title {
          
        margin-top: rem-calc(20);
    }

    .footer-contact {
        line-height: 1.75;
    }

    .footer-location {
        text-align: center;
        font-size: rem-calc(18);
        margin-top: rem-calc(45);
        .icon-map {
            background: url(../images/icon-map.svg) no-repeat;
            width: rem-calc(27);
            height: rem-calc(40);
            background-size: contain;
            display: inline-block;
            margin-bottom: rem-calc(16);
        }
    }

    .copyright {
        text-align: center;
        margin-top: rem-calc(25);
    }

    .menu {
        li:not(:last-child) {
            margin-bottom: rem-calc(20);
        }
        a {
            padding: 0;
        }
    }

    .cell {
        @include breakpoint(small only) {
            + .cell {
                margin-top: rem-calc(30);
            }
        }
    }
}