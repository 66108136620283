section.content-block.template-contact-form {
    .page-title {
        margin-bottom: rem-calc(15);
        span {
            color: color('red');
        }
    }

    .page-subtitle {
        font-weight: 500;
        margin-bottom: rem-calc(60);
        letter-spacing: 2px;
    }

    .address {
        text-align: center;
        text-transform: uppercase;
        color: #595855;
        @include responsive-type($h4, percent($h4, 16));
        font-weight: 500;
        letter-spacing: 3px;
        word-break: break-all;

        p:first-child {
            margin-bottom: rem-calc($p-margin * 2 + 10);
        }
        a {
            color: inherit;
            display: inline-block;
            border-bottom: 2px solid color('red');
            transition: border-color 0.3s;

            &:hover {
                border-bottom-color: transparent;
            }
        }
    }

    form {
        margin-top:  rem-calc($p-margin * 2 + 10);
    }

    + .content-block {
        margin-top: rem-calc(100);
    }
}