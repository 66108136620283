// styleguide:ignore:start
@import 'slick-carousel/slick/slick.scss';
// styleguide:ignore:end

.slick-arrow {
  width: rem-calc(20);
  height: rem-calc(38);
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  display: block;
  font-size: 0;
  cursor: pointer;
  z-index: 10;
  top: 50%;
  margin-top: rem-calc(-19);
}

.slick-prev {
  left: rem-calc(20);
  background-image: url(../images/chevron-left.svg);

  @include breakpoint(1395) {
    left: rem-calc(-40);
  }
}

.slick-next {
  right: rem-calc(20);
  background-image: url(../images/chevron-right.svg);

  @include breakpoint(1395) {
    right: rem-calc(-40);
  }
}

.slick-slide {
  margin: rem-calc(0 15);
}


.slick-list {
  margin: rem-calc(0 -15);
}

.carousel-item {
  img {
    display: block;
    width: 100%;
  }
}

.slick-dots {
  display: flex;
  justify-content: center;
  list-style-type: none;
  margin: 0;
  padding: 0;

  button {
    font-size: 0;
    width: rem-calc(8);
    height: rem-calc(8);
    border: 1px solid $white;
    padding: 0;
    margin: 0;
    background: none;
    border-radius: rem-calc(10);
    background: $white;
  }


  li {
    margin: rem-calc(0 3);
  }
  .slick-active {
    button 
    {
      background: none;
    }
  }
}