.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: color('primary');
  color: color('white');
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  border: 0;
  transition: color $tr, background $tr, border-color $tr;
  padding: rem-calc(10 30);
  min-height: rem-calc(50);
  @include responsive-type(18,14);
  font-weight: 900;
  border-radius: rem-calc(60);

  @include breakpoint(medium) {
    min-height: rem-calc(56);
  }

  &:hover {
    color: color('white');
    background: color('red');
  }
 
  &:focus,
  &:active {
    outline: 0;
  }

  &:disabled,
  &.disabled{
    pointer-events: none;
  }

  &.secondary {
    background: color('red');

    &:hover {
      background: color('primary');
    }
  }

  &.large {
    @include responsive-type(36,22);
    font-weight: 500;
    text-transform: none;

    @include breakpoint(medium) {
      min-height: rem-calc(65);
    }
  }
}
