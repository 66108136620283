section.hero-unit {

    .hero-unit-content {
        max-width: rem-calc(625);
        margin-left: auto;
        margin-right: auto;
        color: $white;
        text-align: center;
        min-height: rem-calc(200);

        h2 {
            @include responsive-type($h1, percent($h1, 20));
            line-height: 1.18;
            span {
                display: block;
            }
        }
    }

    .hero-unit-button {
        text-align: center;
        margin-top: rem-calc(40);
        .button {
            width: 100%;
            max-width: rem-calc(483);
            margin: 0 auto;
        }
    }

    .hero-slider-item {
        padding: rem-calc(60 0);
        background-size: cover;
        background-position: 50% 50%;
        height: 100%;

        @include breakpoint(large) {
            padding: rem-calc(195 0);
        }
    }

    .slick-list {
        margin: 0;
    }

    .slick-slide {
        margin: 0;
        height: auto;

        > div {
            height: 100%;
        }
    }

    .slick-track {
        display: flex;
    }

    + .content-block[class*=" bg-"] {
        margin-top: 0;
    }
}