section.content-block.template-split-content {
    .row {
        margin-bottom: rem-calc(40);

        @include breakpoint(medium) {
            margin-bottom: rem-calc(150);

        }

        .media {
            position: relative;

            img {
                display: block;
                width: 100%;

                + p {
                    margin-top: rem-calc(20);
                    margin-bottom: rem-calc(20);
                }
            }

            a {
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }
        }

        .content {
            background: #ebf0f2;
            display: flex;
            align-items: center;
            padding: rem-calc(20);

            .inner {
                max-width: rem-calc(355);
                margin-left: auto;
                margin-right: auto;
            }
        }

        @include breakpoint(medium) {
            display: flex;

            .content {
                transform: translateY(rem-calc(50));
                position: relative;
                z-index: 2;
                width: 40%;
                min-width:40%;
                padding: rem-calc(50 20);
            }

            .media {
                margin-right: rem-calc(-105);

                p {
                    position: absolute;
                    top: 100%;
                    left: 0;
                }
            }

            &.pull-top {
                margin-top: rem-calc(200) !important;
                margin-bottom: rem-calc(100);

                .content 
                {
                    transform: translateY(rem-calc(-50));
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }

            &:nth-child(odd) {
                .content {
                    order: 1;
                }

                .media {
                    order: 2; 
                    margin-right: 0;
                    margin-left: rem-calc(-105);
                }
            }
        }
    }

    &.align-center {
        @include breakpoint(medium){
            .row {

                &:last-child {
                    margin-bottom: 0;
                }
      
                .content {
                    transform: none;
                    align-self: center;
                    padding-top: 100px;
                    padding-bottom: 100px;

                    h3 {
                        font-size: 28px;
                        line-height: 1.39;
                    }
                }
            }
        }
    }

}