html {
    box-sizing: border-box;
    font-size: 100%;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

body {
    margin: 0;
    padding: 0;
    background: color('white');
    color: color('text');
    font-family: fontFamily('body');
    font-weight: normal;
    @include font-size(14,20);
}

img {
    display: inline-block;
    vertical-align: middle;
    border: 0;
     max-width: 100%;
     height: auto;
     -ms-interpolation-mode: bicubic;
}

a {
    color: color('text');
    text-decoration: none;
    transition: color $tr;

    &:hover,
    &:focus,
    &:active {
        color: color('primary');
    }
}

p {
    margin: rem-calc(0 0 $p-margin);
    padding: 0;

    +ol,
    +ul,
    +h1,
    +h2,
    +h3,
    +h4,
    +h5,
    +h6,
    +div {
        margin-top: rem-calc($p-margin);
    }

    &:last-of-type {
        margin-bottom: 0;
    }
}

ul,ol {
    + ol,
    + ul,
    + h1,
    + h2, 
    + h3,
    + h4,
    + h5,
    + h6,
    + p,
    + div {
        margin-top: rem-calc($p-margin);
    }
}

small {
    font-size: 80%;
}
  
button,
input,
optgroup,
select,
textarea {
  font-family: inherit; 
  font-size: 100%; 
  margin: 0; 
}

button {
    border: 0;
    background: none;
    padding: 0;
}

[type="search"] {
    -webkit-appearance: textfield; 
    outline-offset: -2px; 

    &::-webkit-search-decoration {
        -webkit-appearance: none;
    }
}

::-webkit-file-upload-button {
    -webkit-appearance: button; 
    font: inherit;
}