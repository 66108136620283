.brand {

    img,
    svg {
        width: 100%;
        display: block;
        max-width: rem-calc(80);

        @include breakpoint(large) {
            max-width: rem-calc(182);
        }
    }
}