header.module {
    &.align-center {
        text-align: center;
    }

    .headline {
        span {
            display: block;
        }
    }
}