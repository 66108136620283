section.content-block.template-gallery {
    .gallery-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        max-width: rem-calc(1140 + theme('layout', 'padding') * 2);

        img {
            
            width: 100%;
        }
    }

    img {
        display: block;
    }

    .gallery-item {
        margin-bottom: 20px;
    }

    .gallery-2 {
        .gallery-item {
            margin-bottom: 30px;
        }
    }

    .img-10 {
        margin-top: -6.5%;
    }

    .gallery-title {
        text-align: center;
        margin-top: 20px;
        margin-bottom: 20px;
        font-style: italic;
        font-size: 20px;
        font-weight: bold;
    }
}