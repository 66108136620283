.site-header {
    padding: rem-calc(20 0);
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    background: color('grey');
    z-index: 9999;

    @include breakpoint(large) {
        padding: rem-calc(30 0 20);
    }

   
}

.header-call {
    text-align: right;
    @include responsive-type(25,16);
    font-family: fontFamily('secondary');
    font-weight: 900;
    color: color('red');
    margin-bottom: rem-calc(30);

    a {
        color: color('red') !important;
    }
}