section.content-block.template-testimonials {
    .grid-container {
        max-width: rem-calc(600)
    }

    .title {
        text-transform: uppercase;
        margin-bottom: rem-calc(40);
        &:before {
            content: '';
            background: url(../images/blockquote.svg) no-repeat;
            width: rem-calc(39);
            height: rem-calc(39);
            background-size: contain;
            margin: 0 auto rem-calc(15);
            display: block;
        }
    }

    blockquote {
        font-size: rem-calc(19);
        padding: 0;
        margin: 0;
        line-height: 1.47;
        margin-bottom: rem-calc(20);
    }

    .cite {
        text-transform: uppercase;
        font-size: rem-calc(12)
    }

    .slick-dots {
        margin-top: rem-calc(75);
    }
}