section.content-block.template-featured-gallery {
    .image{
        text-align: center;
        margin-bottom: rem-calc(20);
        display: block;
    }

    .title {
        text-align: center;
        color: #BBB5A7;
        text-align: center;
        display: block;
        font-size: rem-calc(18);
        text-transform: uppercase;
        letter-spacing: 1.84px;
        font-weight: 500;
    }

    @include breakpoint(large) {
        margin-top: rem-calc(150);

        + section.content-block {
            margin-top: rem-calc(120);
        }

        &:last-of-type {
            margin-bottom: rem-calc(120);
        }
    }

    .headline {
        @include responsive-type($h1, percent($h1, 20));
    }
}