.module.text-editor {
    a:not(.button) {
        color: color('primary');

        &:hover {
            text-decoration: underline;
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-bottom: rem-calc($p-margin / 2);
    }

    p.large {
        font-weight: 600;
        @include responsive-type($h3, percent($h3, 20));
    }

    .intro {
        @include responsive-type(21, percent(21, 20));
        font-weight: 500;
        line-height: 1.57;

        &.black {
            font-weight: 700;
        }
    }

    b,strong {
        font-weight: 500;
    }

    a {
        text-decoration: underline;

        &:hover {
            text-decoration: none !important;
        }
    }

    ul {
        li:not(:last-child) {
            margin-bottom: rem-calc(15);
        }
    }

    h1 {
        text-transform: uppercase;
        margin-bottom: rem-calc(80);
    }

    .pdf-link {
        margin-top: rem-calc(50);
        a {
            display: flex;
            align-items: center;
    
            img {
                margin-right: rem-calc(20);
            }
        }
    }

}