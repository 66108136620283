section.content-block.template-color-changer {
    .color-scene {
        position: relative;
        height: rem-calc(600);
        padding: rem-calc(65 0);

        @include breakpoint(large) {
            height: rem-calc(1080);
        }

        .title {
            text-transform: uppercase;
            font-weight: 900;
            margin-bottom: rem-calc(50);
            transition: color 0.6s;
            color: $white;
        }

           
        .color-slide {
            position: absolute;
            @include overlay();
            z-index: 1;
            background-size: cover;
            background-position: 50% 100%;
            padding-top: rem-calc(240);
            opacity: 0;
            transition: opacity 0.6s;

            @include breakpoint(large) {
                padding-top: rem-calc(300);
            }

            &.is-active {
                z-index: 2;
                opacity: 1;
            }
        }

        .color-nav {
            position: relative;
            z-index: 3;
        }

        .color-picker {
            display: flex;
            justify-content: center;

            .color {
                width: rem-calc(65);
                margin: rem-calc(0 5);
                cursor: pointer;

                @include breakpoint(large) {
                    width: rem-calc(114);
                    margin: rem-calc(0 8);
                }
        
            }
        }

        .color-content {
            font-size: rem-calc(18);
            font-weight: 900;
            text-align: center;
            max-width: rem-calc(680);
            transition: color 0.6s;
            color: #000;
        }
    }
}