// Usage: 
// color('primary');
// color('blue', 'base');
// fontFamily('body')
// spacing('margin')
// theme('layout', 'width')

$theme: (
    'colors': (
        'primary': #24384F,
        'white': #fff,
        'black': #000,
        'inverse': #fff,
        'text': #24384F,
        'red' : #C50010, 
        'grey': #D5D5D5,
        'dark-grey': #161615
    ),
    'breakpoints': (
        'small': 0,
        'medium': 768,
        'large': 1024,
        'xlarge': 1280,
        'xxlarge': 1536
    ),
    'fontFamily':(
        'body': 'Gotham HTF',
        'headings': 'Gotham HTF',
        'secondary': 'Museo Sans'
    ),
    'spacing':(
        'margin': 50,
        'padding': 50,
        'module-margin': 50,
        'module-padding': 50
    ),
    'layout':(
        'width': 1140,
        'padding': 25
    ),
    'headings': (
        'h1': 54,
        'h2': 34,
        'h3': 24,
        'h4': 18,
        'h5': 16,
        'h6': 14
    ),
    'extra': (
        'border-radius': 5,
        'transition-time': 0.6s,
        'p-margin': 20
    )
);

$breakpoints : theme('breakpoints');

$base-margin: spacing('margin');
$base-padding: spacing('padding');
$module-margin: spacing('module-margin');
$module-padding: spacing('module-padding');

$border-radius:  theme('extra', 'border-radius');
$tr: theme('extra', 'transition-time');
$p-margin: theme('extra', 'p-margin');

$h1: theme('headings', 'h1');
$h2: theme('headings', 'h2');
$h3: theme('headings', 'h3');
$h4: theme('headings', 'h4');
$h5: theme('headings', 'h5');
$h6: theme('headings', 'h6');