section.content-block {
    @include clearfix;
    @include responsive-margin("top");
    position: relative;
    z-index: 1;

    @include breakpoint(small only) {
        .grid-x .cell.small-order-1 {
            @include responsive-margin("bottom", $module-margin);
        }
    }
}

section[class*=" bg-"] {
    @include responsive-padding("vert");
}

section[class*=" bg-"]+section[class*=" bg-"] {
    margin-top: 0;
}

section.content-block:last-of-type {
    @include responsive-margin("bottom");
}


section:last-of-type[class*=" bg-"] {
    margin-bottom: 0;
}

section.content-block:last-of-type.collapse-vert {
    @include responsive-margin("bottom", $base-margin);
}

section.collapse .grid-x .cell {
    padding-left: 0;
    padding-right: 0;
}

.has-2-cols,
.has-3-cols,
.has-4-cols {
    @include breakpoint(small only) {
        &:not(.block-grid)>.cell+.cell {
            margin-top: rem-calc(20);
        }
    }
}

.block-grid {
    margin-bottom: rem-calc(-30);
    > .cell {
        margin-bottom: rem-calc(30);
    }

    @include breakpoint(large) {
        margin-bottom: rem-calc(-55);
    > .cell {
        margin-bottom: rem-calc(55);
    }
    }
}