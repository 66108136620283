@font-face {
    font-family: 'Gotham HTF';
    font-style: normal;
    font-weight: 900;
    src: url('../fonts/GothamHTF-Ultra.otf');
}

@font-face {
    font-family: 'Gotham HTF';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/GothamHTF-Black.otf');
}


@font-face {
    font-family: 'Gotham HTF';
    font-style: normal;
    font-weight: 600;
    src: url('../fonts/GothamHTF-Bold.otf');
}


@font-face {
    font-family: 'Gotham HTF';
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/GothamHTF-Medium.otf');
}

@font-face {
    font-family: 'Gotham HTF';
    font-style: italic;
    font-weight: 500;
    src: url('../fonts/GothamHTF-MediumItalic.otf');
}

@font-face {
    font-family: 'Gotham HTF';
    font-style: normal;
    font-weight: normal;
    src: url('../fonts/GothamHTF-Book.otf');
}

@font-face {
    font-family: 'Gotham HTF';
    font-style: italic;
    font-weight: normal;
    src: url('../fonts/GothamHTF-BookItalic.otf');
}

@font-face {
    font-family: 'Museo Sans';
    font-style: normal;
    font-weight: 900;
    src: url('../fonts/MuseoSans_900.otf');
}

@font-face {
    font-family: 'Museo Sans';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/MuseoSans_700.otf');
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: fontFamily('headings');
    margin: 0;
    padding: 0;
    line-height: 1.1;
    font-weight: 900;
}

//54
h1,.h1 {
    @include responsive-type($h1, percent($h1, 20));
    line-height: 1.18;
}

//34
h2,.h2 {
    @include responsive-type($h2, percent($h2, 20));
}

//24
h3,.h3 {
    @include responsive-type($h3, percent($h3, 20));
}

//18
h4,.h4 {
    @include responsive-type($h4, percent($h4, 16));
}

//16
h5,.h5 {
    @include responsive-type($h5, percent($h5, 16));
}

//14
h6,.h6 {
    @include responsive-type($h6, percent($h6, 14));
}

.page-title {
    text-transform: uppercase;
    margin-bottom: rem-calc(80);
}